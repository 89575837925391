import {PixiGame} from '../index';
import * as PIXI from 'pixi.js';
import {api} from '../../../App';
import {ReelSymbols, SYMBOLS_CONFIG} from '../../ItemPack/itemPack';
import {Application, Texture} from 'pixi.js';
import {NewGameResponse, SpinEvent, SpinResponse} from '../../../api/types';
import gsap from 'gsap';
import {SymbolItem} from './SymbolItem';
import {arrCopy, arrHaveValue, findExtraSymbols} from '../../../utils/arrayHelpers';
import {EventType, getEvent} from '../../../GameEventBus';
import {onSpinResponse} from "../types/slotMachine";
import {Timer} from "../../../utils/Timer";
import BaseAmount from "../components/BaseAmount";
import {LocalStorage} from "../../../utils/localStorage";
import {testData} from "../../ItemPack/tests";
import {AntisipationData, Cords, SlotEvents} from "./slotEvents";

interface spinInterface {
    isBonusGame?: boolean,
    isDoubleBonusGame?: boolean,
    losslimit?: number,
    singleWinLimit?: number,
    stopIsFreeSpinWon?: boolean,
    stopOnAnyWin?: boolean
}

export class SlotMachine {
    reels: Array<any>;
    game: PixiGame;
    reelContainer?: PIXI.Container;
    specialReels: any
    specialReelsContainer?: PIXI.Container;
    app: Application;
    gameContainer?: PIXI.Container;
    isInitialized: boolean;
    gameInfo: NewGameResponse;
    isRunning: boolean;
    tweening: any[];
    spinData?: SpinResponse;
    previousField?: number[][];
    nextField?: number[][];
    rootContainer?: PIXI.Container;
    betValue: number;
    nextType: 'spin' | 'cascade';
    prevSymbols?: number[][];
    winMap?: number[][];
    wilds?: number[];
    wildSprites?: PIXI.Sprite[];
    symbols: SymbolItem[];
    reelsMask?: PIXI.Sprite
    isFsDropped: boolean
    wildMask?: PIXI.Sprite
    mainReelsContainer: PIXI.Container
    scatterAmount: number
    wildAmount: number
    antisipatorContainer?: PIXI.Container
    minMul: number
    isFetching: boolean
    balance: number;
    currentSpeed: number;
    refreshLimits: boolean
    slotEvents: SlotEvents
    isFs: boolean

    constructor(game: PixiGame) {
        this.game = game;
        this.reels = [];
        this.reelContainer = undefined;
        this.gameContainer = undefined;
        this.app = game.app!;
        this.isInitialized = false;
        this.gameInfo = game.gameInfo!;
        this.isRunning = false;
        this.tweening = [];
        this.previousField = [];
        this.nextField = [];
        this.reelContainer = game.assetsController?.reelsContainer?.container;
        this.betValue = game.slotMachine?.betValue! || (game.initialData?.getBetValue()! || 1);
        this.nextType = 'spin';
        this.wildSprites = [];
        this.symbols = [];
        this.specialReels = {
            container: undefined,
            symbols: []
        }
        this.scatterAmount = 0
        this.wildAmount = 0
        this.isFsDropped = false
        this.mainReelsContainer = new PIXI.Container()
        this.minMul = -1
        this.isFetching = false
        this.balance = 0;
        this.currentSpeed = 1
        this.refreshLimits = false
        this.slotEvents = new SlotEvents(this)
        this.isFs = false
    }

    initialize = async () => {
        this.rootContainer = this.game.assetsController?.reelsContainer?.container;
        if (!this.rootContainer) return;
        this.reelContainer = new PIXI.Container();
        this.reelContainer.sortableChildren = true;
        this.rootContainer?.addChild(this.reelContainer);
        this.reelContainer.width = 1166;
        this.reelContainer.height = 940;
        this.reelContainer.x = this.reelContainer.x + 16;
        this.reelContainer.y = this.reelContainer.y + 14;

        this.antisipatorContainer = new PIXI.Container()
        this.reelContainer.addChild(this.antisipatorContainer)
        this.antisipatorContainer.width = 1166
        this.antisipatorContainer.height = 940
        this.antisipatorContainer.zIndex = 1000

        if (this.isInitialized || !this.gameInfo || !this.reelContainer) {
            return;
        }

        this.specialReelsContainer = new PIXI.Container();

        this.specialReelsContainer.y = 233;
        this.specialReelsContainer.height = 233;
        this.specialReelsContainer.zIndex = 100;
        this.specialReels.container = this.specialReelsContainer
        this.reelContainer.addChild(this.specialReelsContainer);

        this.reelsMask = new PIXI.Sprite(Texture.from("reelsMask"));
        this.reelsMask.width = 1287;
        this.reelsMask.height = 690;
        this.reelsMask.x = -60;
        this.reelsMask.y = 242;
        this.reelsMask.zIndex = 100
        this.reelsMask.visible = true
        this.reelContainer.addChild(this.reelsMask);

        this.wildMask = new PIXI.Sprite(Texture.from('wildMask'))
        this.wildMask.width = 1287
        this.wildMask.height = 960
        this.wildMask.x = -60
        this.wildMask.y = -28
        this.wildMask.visible = true
        this.reelContainer.addChild(this.wildMask)
        this.specialReelsContainer.mask = this.wildMask

        const resources = this.app.loader.resources;
        this.reelContainer.addChild(this.mainReelsContainer)
        this.mainReelsContainer.sortableChildren = true
        this.mainReelsContainer.mask = this.reelsMask
        this.mainReelsContainer.y = 466

        SYMBOLS_CONFIG.forEach((item) => {
            const assetData = resources[item.assetName].spineData!;

            if (!assetData) return;
            const symbol = new SymbolItem(item.id, item.name, assetData);
            this.symbols.push(symbol);
        });


        for (let i = 0; i < 5; i++) {

            const symbols: Array<any> = [];
            const reel = {
                symbols: symbols,
            };

            // Build the symbols
            for (let j = 0; j < 3; j++) {
                const newGameArray: number[][] = this.gameInfo.dynamic.positions;
                let symbol: SymbolItem;
                const index = newGameArray[i][j];

                const s = this.symbols.find((item) => item.id === index);
                if (!s) {
                    continue;
                }

                symbol = s.copyObject();

                symbol.symbol.y = 333 + 229 * j;
                symbol.symbol.x = 78 + 233 * i;
                symbol.symbol.width = 230;
                symbol.symbol.height = 226;

                if (symbol.name === 'SCATTER') {
                    symbol.symbol.width = 300
                    symbol.symbol.x += 0;
                    symbol.symbol.y -= 20;
                    symbol.symbol.zIndex = i + j;
                }

                if (ReelSymbols[index]?.name === 'WILD') {
                    symbol.symbol.width = 233;
                    symbol.symbol.height = 233;
                    symbol.symbol.y += 252;
                }
                reel.symbols.push(symbol);
                this.mainReelsContainer.addChild(symbol.symbol);
            }
            this.reels.push(reel);
        }


        await this.buildSpecialReels([0, 0, 0, 0, 0]);

        document.addEventListener('click', (e) => {
            this.reels.forEach((reel) => {
                reel.symbols.forEach((symbol: SymbolItem) => {
                    symbol.skipAnimation = true
                })
            })
        })
    };

    buildSpecialReels = async (wilds?: number[], index?: number) => {
        return new Promise((resolve) => {
            if (!wilds) {
                return resolve(true);
            }
            if (!this.reelContainer || !this.specialReelsContainer) {
                return resolve(true);
            }

            this.wildSprites?.forEach((sprite) => {
                sprite.destroy();
            });

            this.wildSprites = [];
            const dropReelsAnimation = (i: number, haveDelay = false) => {
                const delay = haveDelay ? 0.05 : 0
                const wiggleTween = gsap.timeline()

                if (wilds[i] > 0) {
                    let wildSprite = this.symbols.find((item) => item.name === 'WILD');
                    if (!wildSprite) return;

                    const clone = wildSprite.copyObject();
                    if (!clone.container) return
                    clone.container.y = -500;
                    clone.container.x = 78 + i * 233;
                    this.specialReelsContainer?.addChild(clone.container);
                    clone.changeMultiplier(wilds[i]);
                    this.specialReels.symbols[i] = clone

                    if (this.wilds && this.wilds[i] === wilds[i]) {
                        clone.container.y = 572
                    } else {
                        wiggleTween.fromTo(
                            clone.container,
                            (0.25 + (i * 0.03)) * this.currentSpeed,
                            {y: clone.container.y},
                            {y: 572, repeat: 0, delay: delay}
                        );

                    }


                }

            }


            if (index === undefined) {
                this.specialReelsContainer.removeChildren(0, 5);
                for (let i = 0; i < 5; i++) {
                    dropReelsAnimation(i)
                    const timer = new Timer(() => {
                        this.wilds = wilds;
                    }, 250)
                    timer.initialize()
                }
            } else {
                dropReelsAnimation(index, true)
            }

            return resolve(true);
        });
    };

    changeSymbols = (reels: number[][], wildMap: number[][]) => {
        return new Promise((resolve) => {
            for (let i = 0; i < this.reels.length; i++) {
                for (let j = 0; j < this.reels[i].symbols.length; j++) {
                    this.mainReelsContainer.removeChildren(0, 14);
                }
            }
            for (let i = 0; i < this.reels.length; i++) {
                const reel = this.reels[i];
                for (let j = 0; j < this.reels[i].symbols.length; j++) {
                    const symbol = this.symbols.find((item) => item.id === reels[i][j]);
                    const clone = symbol?.copyObject();

                    if (clone) {
                        clone.symbol.y = 333 + 229 * j;

                        if (clone.name === 'WILD' && clone.container) {
                            clone.symbol.y += 255;
                            clone.symbol.x += 78
                            clone.container.x = 233 * i;
                            this.mainReelsContainer.addChild(clone.container!)
                            clone.changeMultiplier(wildMap[i][j], j, true)
                        } else {
                            this.mainReelsContainer.addChild(clone.symbol);
                            clone.symbol.x = 78 + 233 * i;
                        }
                        if (clone.name === 'SCATTER') {
                            clone.symbol.y -= 20;
                            clone.symbol.x += 0;
                            clone.symbol.zIndex = (i + 1) * (j + 1)
                        }

                        this.reels[i].symbols[j] = clone
                    }
                }
            }
            return resolve(true);
        });
    };

    changeMulSymbols = (specialReel: number[]) => {
        return new Promise(async (resolve) => {
            for (let i = 0; i < 5; i++) {
                if (specialReel[i] > 0 && specialReel[i] < this.minMul) {
                    const wiggleTweenMult = gsap.timeline()

                    wiggleTweenMult.fromTo(
                        this.specialReels.symbols[i].texture.scale,
                        0.35 * this.currentSpeed,
                        {x: 1, y: 1},
                        {x: 0, y: 0, repeat: 0}
                    )
                }
            }
            setTimeout(async () => {
                for (let i = 0; i < 5; i++) {
                    if (specialReel[i] > 0 && specialReel[i] < this.minMul) {
                        const wiggleTweenWild = gsap.timeline()
                        const wiggleTweenMult = gsap.timeline()

                        this.specialReels.symbols[i].changeSpecialReelsMultiplier(this.minMul);
                        wiggleTweenWild.fromTo(
                            this.specialReels.symbols[i].container.scale,
                            0.5 * this.currentSpeed,
                            {x: 1, y: 1},
                            {x: 1.05, y: 1.05, repeat: 0}
                        );
                        wiggleTweenMult.fromTo(
                            this.specialReels.symbols[i].texture.scale,
                            0.5 * this.currentSpeed,
                            {x: 0, y: 0},
                            {x: 1.05, y: 1.05, repeat: 0}
                        )
                        wiggleTweenWild.fromTo(
                            this.specialReels.symbols[i].container.scale,
                            0.5 * this.currentSpeed,
                            {x: 1.05, y: 1.05},
                            {x: 1, y: 1, repeat: 0}
                        )
                        wiggleTweenMult.fromTo(
                            this.specialReels.symbols[i].texture.scale,
                            0.05 * this.currentSpeed,
                            {x: 1.05, y: 1.05},
                            {x: 1, y: 1, repeat: 0}
                        )
                    }
                }
                setTimeout(() => {
                    return resolve(true)
                }, 500 * this.currentSpeed)
            }, 350 * this.currentSpeed)
        })
    };

    dropMainReels = async () => {
        return new Promise((resolve) => {

            let i = 0
            this.game.soundController.reelFallSound?.play()
            const interval = setInterval(() => {
                if (i > 4) {
                    clearInterval(interval)
                    const timer = new Timer(() => {
                        const interval = setInterval(() => {
                            if (!this.isFetching) {
                                clearInterval(interval)
                                return resolve(true)
                            }
                        }, 10)

                    }, 500 * this.currentSpeed)
                    timer.initialize()


                } else {
                    for (let j = 0; j < 3; j++) {
                        const wiggleTween = gsap.timeline();
                        if (this.reels[i].symbols[j].name === 'WILD') {
                            if (this.reels[i].symbols[j].container.children.length > 0) {
                                const dropWildSymbol = gsap.timeline()

                                dropWildSymbol.fromTo(
                                    this.reels[i].symbols[j].symbol,
                                    (0.25 + (i * 0.03)) * this.currentSpeed,
                                    {y: this.reels[i].symbols[j].symbol.y},
                                    {
                                        y: this.reels[i].symbols[j].symbol.y - 233 * (j + 1),
                                        repeat: 0,
                                        delay: (0.05 * (2 - j)) * this.currentSpeed
                                    }
                                )

                                wiggleTween.fromTo(
                                    this.reels[i].symbols[j].container,
                                    (0.25 + (i * 0.03)) * this.currentSpeed,
                                    {y: this.reels[i].symbols[j].container.y},
                                    {y: 1003 + 233 * (j + 1), repeat: 0, delay: (0.05 * (2 - j)) * this.currentSpeed}
                                )
                            } else {
                                wiggleTween.fromTo(
                                    this.reels[i].symbols[j].symbol,
                                    (0.25 + (i * 0.03)) * this.currentSpeed,
                                    {y: this.reels[i].symbols[j].symbol.y},
                                    {y: 1100 + 233 * (j + 1), repeat: 0, delay: (0.05 * (2 - j)) * this.currentSpeed}
                                )
                            }

                        } else {
                            wiggleTween.fromTo(
                                this.reels[i].symbols[j].symbol,
                                (0.25 + (i * 0.03)) * this.currentSpeed,
                                {y: this.reels[i].symbols[j].symbol.y},
                                {y: 1100 + 233 * (j + 1), repeat: 0, delay: (0.05 * (2 - j)) * this.currentSpeed}
                            )
                        }

                        if (j === 2) {
                            const wild = this.specialReels.symbols[i]

                            if (wild?.container) {
                                const wildDrop = gsap.timeline()
                                wildDrop.fromTo(
                                    wild.container,
                                    {y: wild.container.y},
                                    {
                                        y: 1100 + 233 * j,
                                        repeat: 0,
                                        delay: (0.05 * (4 - j)) * this.currentSpeed,
                                        duration: (0.25 + (i * 0.03)) * this.currentSpeed
                                    }
                                )
                            }
                        }
                    }

                }
                i++
            }, 40 * this.currentSpeed)
        })

    }

    buildMainReels = async (reels: number[][], winMap: number[][], specialReel: number[], wildMap: number[][], events: SpinEvent[], payLines: number[]) => {

        if (!!events.length) {
            for (let eventItem = 0; eventItem < events.length; eventItem++) {
                const timer = new Timer(() => {
                    getEvent(EventType.GAME_WIN_VALUE).send(events[eventItem].win / 100)
                }, 2000 * this.currentSpeed)

                timer.initialize()
            }
        }
        return new Promise(async (resolve, reject) => {
            if (this.nextType === 'cascade') {
                await this.cascade(this.prevSymbols!, reels, winMap);
                await this.dropExtraSymbols(reels, wildMap);
                await this.buildSpecialReels(specialReel);
                await this.changeSymbols(reels, wildMap)
                await this.reelsComplete(winMap, specialReel!, events, payLines);
                return resolve(true)
            }

            const antisipation = await this.slotEvents.getAntisipationPositions(reels)

            console.log(`ANTISIPATION`, antisipation)

            this.reels.forEach((reel, i) => {
                reel.symbols.forEach((symbol: SymbolItem, j: number) => {
                    if (symbol.name === 'SCATTER') {
                        symbol.symbol.zIndex = (i + 1) * (j + 1)
                    }
                })
            })


            await this.changeSymbols(reels, wildMap);
            this.removeTopChild()
            this.reels.forEach((reel) => {
                reel.symbols.forEach((symbol: SymbolItem, index: number) => {
                    if (symbol.name === "WILD" && symbol.container) {
                        symbol.container.y = -960 + index
                    } else {
                        symbol.symbol.y = -600 + (233 * index)
                    }
                })
            })
            let i = 0

            const interval2 = setInterval(() => {
                if (i > 4) {
                    clearInterval(interval2)
                    this.wilds = specialReel;
                } else {
                    const reel = this.reels[i];
                    this.buildSpecialReels(specialReel, i);

                    if(antisipation && i == antisipation.startFrom) {
                        this.slotEvents.showAntisipation(antisipation.positions, antisipation.scattersX)
                    }

                    for (let j = 0; j < 3; j++) {
                        const delay = ((0.05 * (2 - j)) * this.currentSpeed) + (antisipation && i >= antisipation.startFrom  ? 1 : 0)
                        const wiggleTween = gsap.timeline();
                        if (reel.symbols[j].name === 'WILD') {
                            wiggleTween.fromTo(
                                reel.symbols[j].container,
                                (0.25 + (i * 0.03)) * this.currentSpeed,
                                {y: reel.symbols[j].container.y},
                                {y: 0, repeat: 0, delay: delay}
                            ).then(() => {
                                if (j === 2) {
                                    this.game.soundController.reelStopSound?.play()
                                }

                                reel.symbols[j].shake()
                            })

                        } else if (reel.symbols[j].name === 'SCATTER') {
                            wiggleTween.fromTo(
                                reel.symbols[j].symbol,
                                (0.25 + (i * 0.03)) * this.currentSpeed,
                                {y: reel.symbols[j].symbol.y},
                                {y: 310 + 229 * j, repeat: 0, delay: delay}
                            ).then(() => {

                                if (j === 2) {
                                    this.game.soundController.reelStopSound?.play()
                                }

                                reel.symbols[j].shake().then(() => {
                                    this.scatterAmount++
                                    switch (this.scatterAmount) {
                                        case 1:
                                            this.game.soundController.scatterLand1?.play()
                                            break;
                                        case 2:
                                            this.game.soundController.scatterLand2?.play()
                                            break;
                                        case 3:
                                            this.game.soundController.scatterLand3?.play()
                                            break;
                                        case 4:
                                            this.game.soundController.scatterLand4?.play()
                                            break;
                                        case 5:
                                            this.game.soundController.scatterLand5?.play()
                                            break;
                                    }
                                })
                            })
                        } else {
                            wiggleTween.fromTo(
                                reel.symbols[j].symbol,
                                (0.25 + (i * 0.03)) * this.currentSpeed,
                                {y: reel.symbols[j].symbol.y},
                                {y: 333 + 229 * j, repeat: 0, delay: delay}
                            ).then(() => {
                                if (j == 2) {
                                    this.game.soundController.reelStopSound?.play()
                                }
                                reel.symbols[j].shake()
                            })
                        }
                    }

                }


                i++
            }, 80 * this.currentSpeed)

            const timer2 = new Timer(async () => {
                await this.reelsComplete(winMap, specialReel!, events, payLines);
                return resolve(true);
            }, (1500 * this.currentSpeed) + (antisipation ? 1000 : 0) )
            timer2.initialize()

        });
    };

    bonusTimeOut = () => {
        return new Promise((resolve) => {
            const timer = new Timer(() => {
                return resolve(true)
            }, 200 * this.currentSpeed)
            timer.initialize()
        })
    }
    onSpin = ({stopIsFreeSpinWon, stopOnAnyWin, singleWinLimit, losslimit, isBonusGame}: spinInterface) => {
        this.game.assetsController!.winFinishPopUp!.stopIfFSWon = (stopIsFreeSpinWon === undefined ? false : stopIsFreeSpinWon);
        return new Promise(async (resolve) => {
            if (this.balance <= this.betValue) {
                this.game.assetsController?.changeActiveButtonPlay(false);
                getEvent(EventType.OUT_OF_MONEY).send(true);
                return;
            }

            if (!this.game.assetsController?.isActiveButtonPlay) return;
            if (!this.gameInfo) return;
            if (this.isRunning) return;
            this.getCurrentSpeed()

            this.antisipatorContainer?.removeChildren()
            this.scatterAmount = 0


            getEvent(EventType.GAME_WIN_VALUE).send(undefined)

            this.game.assetsController?.winInput?.changeValue(0);

            this.game.soundController.spinSound?.play();
            this.isRunning = true;

            getEvent(EventType.GAME_ON_SPIN_RUNNING).send(true);

            this.changeButtonState(true);

            console.log(`game type`, this.game.type)

            if (this.game.type === 'default') {
                this.isFetching = true
                api.auth.spin(
                    this.gameInfo.token,
                    'spin',
                    this.betValue * 100,
                    stopOnAnyWin,
                    stopIsFreeSpinWon,
                    singleWinLimit,
                    losslimit,
                    this.refreshLimits
                ).then((res) => {
                    this.spinData = res;
                    this.isFetching = false;
                    this.refreshLimits = false;
                })
            }

            if (this.game.type === 'doubleBonusGame') {
                this.isFetching = true
                api.auth.buyDoubleBonus(
                    this.gameInfo.token,
                    'spin',
                    this.betValue * 100,
                    stopOnAnyWin,
                    stopIsFreeSpinWon,
                    singleWinLimit,
                    losslimit,
                    this.refreshLimits
                ).then((res) => {
                    this.spinData = res
                    this.isFetching = false
                    this.refreshLimits = false;
                })
            }

            await this.dropMainReels();

            if (this.game.type === 'debug') {
				const defaultDebugValue = {
					values: {
						positions:
							'[[[5,3,4],[6,8,3],[4,10,3],[5,7,3],[10,6,5]],[[6,5,4],[1,6,8],[5,4,10],[8,5,7],[10,6,5]]]',
						wilds: '[[0,15,0,0,0],[0,0,0,0,0]]',
					},
				};
				const data = localStorage.getItem('debugReels');
				const debugReels = data === null ? defaultDebugValue : JSON.parse(data!);
				console.log(debugReels);
				this.spinData = await api.auth.debugReels(
					this.gameInfo.token,
					'spin',
					this.betValue * 100,
					JSON.parse(debugReels.values.positions),
					JSON.parse(debugReels.values.wilds)
				);
			}

            if (!this.spinData) {
                return
            }
            ;

            if (isBonusGame) {
                await this.bonusTimeOut()
            }

            let {rounds, freespins, isAutoPlayStopped} = this.spinData;
            // rounds = testData
            for (let i = 0; i < rounds.length; i++) {
                let {winMap, positions, specialReel, wildMap, events, payLines} = rounds[i].reels;

                await this.buildMainReels(positions, winMap, specialReel, wildMap, events, payLines);
                this.winMap = winMap;
                this.prevSymbols = positions;

            }

            await this.showWinModal()
            this.isRunning = false;
            getEvent(EventType.GAME_ON_SPIN).send(this.spinData);

            getEvent(EventType.GAME_ON_SPIN_RUNNING).send(false);

            if (freespins && (freespins.left >= 0 || freespins.added > 0)) {
                this.isFsDropped = true
                this.changeButtonState(true)
                this.game.assetsController?.playButton?.changeAplhaAndButtonMode(true);

                setTimeout(() => {
                    this.reels.forEach((reel) => {
                        reel.symbols.forEach((symbol: SymbolItem) => {
                            if (symbol.name === "SCATTER") symbol.win()
                        })
                    })
                    this.game.soundController.scatterWin?.play();
                }, 500 * this.currentSpeed)
                setTimeout(() => {
                    this.game?.assetsController?.changeGameType(true)
                    this.game.assetsController?.winInput?.changeValue(0);
                    this.game.assetsController?.multiplierArray?.forEach(item => {
                        item.resetItem()
                    });
                    this.game.assetsController?.multiplierMobileArray?.forEach(item => {
                        item.resetItem()
                    });
                    this.minMul = 1
                    this.game.assetsController?.winStartPopUp?.showModal(true, `${freespins.total}`)
                    return resolve(onSpinResponse.FREE_SPIN_WON)
                }, 2000 * this.currentSpeed)

            } else (
                this.game.assetsController.changeGameType(false)
            )

            if (freespins && (freespins.left >= 0 || freespins.added > 0)) {
                return resolve(onSpinResponse.STOP_AUTOSPIN)
            }

            if (isAutoPlayStopped) {
                return resolve(onSpinResponse.STOP_AUTOSPIN)
            }


            const timer = new Timer(() => {
                if (this.isFsDropped) return
                this.isFsDropped = false
                this.changeButtonState(false);
            }, 400 * this.currentSpeed)
            timer.initialize()

            if (!this.isFsDropped) {
                this.game.assetsController?.playButton?.changeAplhaAndButtonMode(false);
            }
            return resolve(true)
        })
    };

    onBonusSpin = async (spinData: SpinResponse) => {
        const currentGameType = this.game.type
        this.game.type = 'bonusGame'

        this.spinData = spinData
        await this.onSpin({isBonusGame: true})

        this.game.type = currentGameType
    }

    startAutoPlayFS = async (value: number, bet: number) => {
        this.game.assetsController!.playButton!.buttonBorderFS.alpha = 0.75;
        this.game.assetsController!.playButton!.buttonBorder.alpha = 0;
        this.game.assetsController!.playButton!.buttonStop.alpha = 0;
        this.game.assetsController!.playButton!.buttonStopHover.alpha = 0;

        this.game.assetsController?.autoPlayButton?.setFSButtonState(false)
        this.antisipatorContainer?.removeChildren()
        this.getCurrentSpeed(false, true)
        this.isFs = true
        const timer = new Timer(async () => {
            this.game.soundController.spinSound?.play();
            this.isRunning = true;
            getEvent(EventType.GAME_ON_SPIN_RUNNING).send(true);
            this.changeButtonState(true);
            this.removeTopChild();
            this.game.assetsController?.autoPlayButton?.setButtonState(true);
            this.game.assetsController?.playButton?.setButtonState(true);
            value -= 1
            const betIndex = this.game.assetsController?.betInput?.betValueIndex!
            const bet = this.game.assetsController?.betInput?.totalBetList[betIndex]!
            this.game.assetsController?.autoPlayButton?.setSpinCounter(value);
            this.game.assetsController?.playButton?.setSpinCounter(value);
            api.auth.spin(this.gameInfo.token, 'freespins', bet * 100).then((res) => {
                this.spinData = res
                this.isFetching = false
            })

            await this.dropMainReels()


            if (!this.spinData) return;

            let {rounds} = this.spinData;
            for (let i = 0; i < rounds.length; i++) {
                let {winMap, positions, specialReel, wildMap, events, payLines} = rounds[i].reels;

                await this.buildMainReels(positions, winMap, specialReel, wildMap, events, payLines);
                this.winMap = winMap;
                this.prevSymbols = positions;

            }

            getEvent(EventType.GAME_AUTO_SPIN_COUNT).send(value === 0 ? '' : value);
            getEvent(EventType.GAME_ON_SPIN).send(this.spinData);
            await this.showWinModal()
            if (value === 0) {
                const totalWin = this.game.assetsController?.winInputLandscape?.text.text
                getEvent(EventType.GAME_ON_SPIN_RUNNING).send(false);
                this.game.assetsController?.autoPlayButton?.setButtonState(false);
                this.game.assetsController?.playButton?.setButtonState(false);
                const endTimer = setTimeout(() => {
                    this.game.assetsController?.winFinishPopUp?.showModal(true)
                    this.isRunning = false;
                    this.game.assetsController?.winFinishPopUp?.setValue(`$${totalWin}`)
                    this.game.assetsController?.autoPlayButton?.setFSButtonState(true)
                    this.game.assetsController?.playButton?.changeAplhaAndButtonMode(false);
                    clearTimeout(endTimer)
                    this.isFs = false
                }, 500 * this.currentSpeed)
                this.minMul = -1;
                this.isFsDropped = false
                this.changeButtonState(false)
                return timer.clear()
            }
            await this.startAutoPlayFS(value, bet)
        }, 1000 * this.currentSpeed);

        timer.initialize()
    }

    // Building a new reels, after win line
    buildWinMup = async (winMap: number[][], specialReel: number[], events: SpinEvent[], payLines: number[]) => {
        if (!winMap) return;
        let lastSymbol: SymbolItem | undefined;

        return new Promise(async (resolve) => {
            const winMapCopy = await arrCopy(winMap);
            const symbols = []
            let prevMinMul = this.minMul
            let isWinLine = false;
            // await this.game.assetsController?.winLine?.depictWinLines(payLines)
            for (let i = 0; i < events.length; i++) {
                await this.CheckForWin(events[i], i)
            }


            for (let i = 0; i < winMap.length; i++) {
                for (let j = 0; j < winMap[i].length; j++) {
                    const winPos = winMap[i][j];

                    if (winPos === -1) {
                        isWinLine = true;
                        const indexToDelete = winMapCopy[i].indexOf(-1);
                        winMapCopy[i].splice(indexToDelete, 1);

                        const hasWin = await arrHaveValue(winMapCopy, -1);
                        if (hasWin) {
                            this.reels[i].symbols[j].winDestructionAnimation()

                            if (this.minMul > 0 &&
                                this.reels[i].symbols[j].name === "WILD" &&
                                this.reels[i].symbols[j].mult > this.minMul
                            ) {
                                this.minMul = this.reels[i].symbols[j].mult
                            }
                        } else {
                            lastSymbol = this.reels[i].symbols[j];
                            if (this.minMul > 0 &&
                                this.reels[i].symbols[j].name === "WILD" &&
                                this.reels[i].symbols[j].mult > this.minMul
                            ) {
                                this.minMul = this.reels[i].symbols[j].mult
                            }
                        }
                    }
                }
            }
            await lastSymbol?.winDestructionAnimation();
            if (isWinLine) {
                this.game.soundController.win_counter_end?.play()
                this.game.soundController.winSound?.play()
            }
            if (this.minMul > 0 &&
                lastSymbol?.name === 'WILD' &&
                lastSymbol.mult > this.minMul
            ) {
                this.minMul = lastSymbol.mult
            }
            if (prevMinMul < this.minMul) {
                this.game.assetsController?.multiplierArray?.forEach(item => {
                    item.disableInactive(this.minMul)
                });
                this.game.assetsController?.multiplierMobileArray?.forEach(item => {
                    item.disableInactive(this.minMul)
                });
                await this.changeMulSymbols(specialReel)
            }

            return resolve(isWinLine);
        });

    };

    reelsComplete = async (winMap: number[][], specialReel: number[], events: SpinEvent[], payLines: number[]) => {
        return new Promise(async (resolve) => {
            this.previousField = this.nextField;
            const hasWin = await this.buildWinMup(winMap, specialReel, events, payLines);
            if (hasWin) {
                this.nextType = 'cascade';
            } else {
                this.nextType = 'spin';
            }
            const timer = new Timer(() => {

                return resolve(true);
            }, 200 * this.currentSpeed)

            timer.initialize()
        });

    };

    cascade = (prevPositions: number[][], positions: number[][], winMap: number[][]) => {
        return new Promise(async (resolve) => {
            const changedSymbols: number[][] = [];
            for (let i = 0; i < 5; i++) {
                for (let j = 0; j < 3; j++) {
                    if (!changedSymbols[i]) changedSymbols[i] = [];
                    if (prevPositions[i][j] !== positions[i][j]) {
                        changedSymbols[i].push(1);
                    } else {
                        changedSymbols[i].push(0);
                    }
                }
            }
            for (let i = 0; i < changedSymbols.length; i++) {
                const sum = changedSymbols[i].reduce((prev, curr) => prev + curr);

                await this.doCascade(i, sum, winMap);
            }
            this.nextType = 'spin';

            const cascadeTimer = new Timer(() => {
                return resolve(true);
            }, 300 * this.currentSpeed);

            cascadeTimer.initialize()
        });
    };

    doCascade = async (reelIndex: number, sum: number, winMap: number[][]): Promise<boolean> => {
        return new Promise(async (resolve) => {
            if (!this.winMap || !this.prevSymbols) {
                return;
            }
            const currentReel = this.winMap[reelIndex];
            const reel = this.reels[reelIndex]
            if (currentReel[0] !== -1 && currentReel[1] === -1 && currentReel[2] !== -1) {
                let wiggleTween = gsap.timeline();
                wiggleTween.fromTo(
                    reel.symbols[0].symbol,
                    0.2 * this.currentSpeed,
                    {y: reel.symbols[0].symbol.y},
                    {y: reel.symbols[0].symbol.y + 233, repeat: 0}
                ).then(() => {
                    reel.symbols[0].shake()
                    this.game.soundController.reelStopSound?.play()
                })
            } else if (currentReel[2] === -1 && currentReel[1] !== -1 && currentReel[0] !== -1) {
                let wiggleTween = gsap.timeline();
                const wiggleTween2 = gsap.timeline()
                wiggleTween.fromTo(
                    reel.symbols[1].symbol,
                    0.2 * this.currentSpeed,
                    {y: reel.symbols[1].symbol.y},
                    {y: reel.symbols[1].symbol.y + 233, repeat: 0}
                ).then(() => {
                    reel.symbols[1].shake()
                })

                wiggleTween2.fromTo(
                    reel.symbols[0].symbol,
                    0.2 * this.currentSpeed,
                    {y: reel.symbols[0].symbol.y},
                    {y: reel.symbols[0].symbol.y + 233, repeat: 0}
                ).then(() => {
                    reel.symbols[0].shake()
                    this.game.soundController.reelStopSound?.play()
                })

            } else if (currentReel[2] === -1 && currentReel[1] === -1 && currentReel[0] !== -1) {
                //    drop to symbols from top
                const wiggleTween = gsap.timeline()
                wiggleTween.fromTo(
                    reel.symbols[0].symbol,
                    0.2 * this.currentSpeed,
                    {y: reel.symbols[0].symbol.y},
                    {y: reel.symbols[0].symbol.y + 466, repeat: 0}
                ).then(() => {
                    reel.symbols[0].shake()
                    this.game.soundController.reelStopSound?.play()
                })
            } else if (currentReel[2] === -1 && currentReel[1] !== -1 && currentReel[0] === -1) {
                const wiggleTween = gsap.timeline()
                wiggleTween.fromTo(
                    reel.symbols[1].symbol,
                    0.2 * this.currentSpeed,
                    {y: reel.symbols[1].symbol.y},
                    {y: reel.symbols[1].symbol.y + 233, repeat: 0}
                ).then(() => {
                    reel.symbols[1].shake()
                    this.game.soundController.reelStopSound?.play()
                })
            }

            return resolve(true)

        });

    };

    //dropping symbols from a head while cascade is running
    dropExtraSymbols = async (reels: number[][], wildMap: number[][]) => {
        if (!this.winMap || !this.prevSymbols) return;
        const newPositions = await findExtraSymbols(this.winMap, this.prevSymbols);

        let antisipation: AntisipationData | undefined  = await this.slotEvents.getAntisipationPositions(reels, wildMap, this.isFs, true)

        if(antisipation) {
            const cords:Cords[] = []
            // GENERATE CORDS FROM POSITIONS
            for (let i = 0; i < newPositions.length; i++) {
                const symbols = newPositions[i]
                for(let j = 0; j < symbols.length; j++ ) {
                    if(symbols[j] === -1 ) {
                        if(!antisipation?.scattersX?.includes(i)) {
                            cords.push({x: i, y: j})
                        } else {
                            cords.push({x: i, y: j})
                        }
                    }
                }
            }
            if(!cords.length) antisipation = undefined
            else this.slotEvents.showAntisipation(cords, antisipation.scattersX, true)
        }

        const wilds = [...this.wilds!]

        console.log(`NEW POSITIONS`, newPositions)

        return new Promise(async (resolve) => {
            for (let i = 0; i < 5; i++) {
                for (let j = 2; j >= 0; j--) {
                    if (newPositions[i][j] !== -1) continue;
                    const symbolIndex = reels[i][j];
                    const symbol = this.symbols.find((item) => item.id === symbolIndex);
                    const clone = symbol?.copyObject();
                    if (!clone)  return

                    this.reels[i].symbols[j] = clone
                    clone.symbol.x = 75 + i * 2;
                    if (clone.name !== 'WILD') {
                        clone.symbol.y = -200;
                    } else {
                        if (clone.container) {
                            clone.container.y = -200
                        }
                    }
                    if (clone.name === 'SCATTER') {
                        this.reels[i].symbols[j].symbol.zIndex = (i + 1) * (j + 1)
                    }


                    const delay = (0.01 * (2 - j)) * this.currentSpeed + (antisipation && i >= antisipation.startFrom ? 1.1 : 0)

                    const dropAnim = gsap.timeline();
                    if (wilds && wilds[i] !== 0 && clone.name === "WILD") {
                        const wild = this.specialReels.symbols[i]
                        wilds[i] = 0

                        const durationMult = j === 0 ? 0.5 : 1
                        console.log(`REEL ${i} Y ${j}`)
                        dropAnim.fromTo(
                            wild.container,
                            ((0.2 + (i * 0.06)) * durationMult) * this.currentSpeed,
                            {y: wild.container.y},
                            {y: 590 + 233 * j, repeat: 0, delay: delay}
                        ).then(() => {
                            this.mainReelsContainer.addChild(wild.container);
                            wild.shake()
                            // wild.container.y -= 233
                            this.game.soundController.wildLand?.play()
                            this.game.soundController.reelStopSound?.play()
                        })


                    } else if (clone.name === 'WILD' && clone.container) {
                        console.log(`else J = ${j}`)

                        this.mainReelsContainer.addChild(clone.container);
                        clone.container.x = 233 * i;
                        const posY = 572 + 233 * j
                        dropAnim.fromTo(
                            clone.container,
                            (0.2 + (i * 0.06)) * this.currentSpeed,
                            {y: clone.container.y},
                            {y: 590 + 233 * j, repeat: 0, delay: delay}
                        ).then(() => {
                            clone.shake()
                            this.game.soundController.wildLand?.play()
                            this.game.soundController.reelStopSound?.play()
                        })
                    } else {
                        clone.symbol.x = 75 + 233 * i;
                        this.mainReelsContainer.addChild(clone.symbol);
                        dropAnim.fromTo(
                            clone.symbol,
                            (0.2 + (i * 0.06)) * this.currentSpeed,
                            {y: clone.symbol.y},
                            {y: 350 + 233 * j, repeat: 0, delay: delay}
                        ).then(() => {
                            clone.shake().then(() => {
                                if (this.reels[i].symbols[j].name === "SCATTER") {
                                    this.scatterAmount++
                                    switch (this.scatterAmount) {
                                        case 1:
                                            this.game.soundController.scatterLand1?.play()
                                            break;
                                        case 2:
                                            this.game.soundController.scatterLand2?.play()
                                            break;
                                        case 3:
                                            this.game.soundController.scatterLand3?.play()
                                            break;
                                        case 4:
                                            this.game.soundController.scatterLand4?.play()
                                            break;
                                        case 5:
                                            this.game.soundController.scatterLand5?.play()
                                            break;
                                    }

                                }
                            })
                            this.game.soundController.reelStopSound?.play()
                        })
                    }
                }
            }
            this.nextType = 'spin';
            const timer = new Timer(() => {
                return resolve(true);
            }, 600 * this.currentSpeed + (antisipation ? 1100 : 0));

            timer.initialize()

        });

    };

    changeButtonState = (value: boolean) => {
        if (!this.game.assetsController?.playButton) return;
        if (!this.game.assetsController.autoPlayButton) return;
        this.game.assetsController!.isSpacePressed = value;
        this.game.assetsController?.playButton?.setButtonState(true);
        if (this.game.assetsController.doubleBonusChanceButton?.isDBCActive === false) {
            this.game.assetsController!.buyBonusButton!.button.interactive = !value;
            this.game.assetsController!.buyBonusButton!.button.buttonMode = !value;
        }
        this.game.assetsController!.doubleBonusChanceButton!.button.interactive = !value;
        this.game.assetsController!.doubleBonusChanceButton!.button.buttonMode = !value;
        this.changeBetButtonsState(value)
        if (this.balance < this.betValue) {
            this.game.assetsController?.playButton?.changeAplhaAndButtonMode(false);
            this.changeBetButtonsState(false);
        }
    };

    changeBetButtonsState(value: boolean) {
        if (value === true && this.game.assetsController!.autoPlayModal!.isAutoPlayRunning === false) {
            this.game.assetsController?.betInput?.changeButtonsState(!value);
            this.game.assetsController?.betButton?.changeButtonState(!value);
            this.game.assetsController?.buyBonusButton?.changeButtonState(!value);
            if (this.game.assetsController!.doubleBonusChanceButton!.isDBCActive === false) {
                this.game.assetsController?.buyBonusButton?.changeButtonState(!value);
            }
            this.game.assetsController?.doubleBonusChanceButton?.changeButtonState(!value);
        } else if (value === false && this.game.assetsController!.autoPlayModal!.isAutoPlayRunning === false) {
            if (!this.isRunning) {
                this.game.assetsController?.playButton?.setButtonState(false);
            }
            // if (window.location.pathname === '/test') {
            //     this.game.type = 'debug';
            // } else {
            //     this.game.type = 'default';
            // }
            this.game.assetsController?.betInput?.changeButtonsState(!value);
            this.game.assetsController?.betButton?.changeButtonState(!value);
            this.game.assetsController?.buyBonusButton?.changeButtonState(!value);
            this.game.assetsController?.doubleBonusChanceButton?.changeButtonState(!value);
        } else if (value === false && this.game.assetsController!.autoPlayModal!.isAutoPlayRunning === true) {
            this.game.assetsController?.betInput?.changeButtonsState(!this.game.assetsController?.autoPlayModal?.isAutoPlayRunning);
            this.game.assetsController?.betButton?.changeButtonState(!this.game.assetsController?.autoPlayModal?.isAutoPlayRunning);
            this.game.assetsController?.buyBonusButton?.changeButtonState(!this.game.assetsController?.autoPlayModal?.isAutoPlayRunning);
            this.game.assetsController?.doubleBonusChanceButton?.changeButtonState(!this.game.assetsController?.autoPlayModal?.isAutoPlayRunning);
        } else if (value === true && this.game.assetsController!.autoPlayModal!.isAutoPlayRunning === true) {
            this.game.assetsController?.betInput?.changeButtonsState(!this.game.assetsController?.autoPlayModal?.isAutoPlayRunning);
            this.game.assetsController?.betButton?.changeButtonState(!this.game.assetsController?.autoPlayModal?.isAutoPlayRunning);
            this.game.assetsController?.buyBonusButton?.changeButtonState(!this.game.assetsController?.autoPlayModal?.isAutoPlayRunning);
            this.game.assetsController?.doubleBonusChanceButton?.changeButtonState(!this.game.assetsController?.autoPlayModal?.isAutoPlayRunning);
        }
        if (this.game.assetsController?.doubleBonusChanceButton?.isDBCActive === true) {
            this.game.type = 'doubleBonusGame';
            this.game.assetsController!.buyBonusButton!.button.alpha = 0.5;
            this.game.assetsController!.buyBonusButton!.setBuyBonusButtonState(false);
        }
    }

    removeTopChild() {
        if (!this.specialReelsContainer) return;
        this.specialReelsContainer.removeChildren();
    }

    changeBetValue = (value: number) => {
        this.game.initialData?.setBetValue(value);
        this.betValue = Number(value.toFixed(2));
    };

    showWinModal = async () => {
        return new Promise(async (resolve, reject) => {
            if (!this.spinData) return reject()
            if (this.spinData?.winDescription) {
                this.game.assetsController?.winModal?.openModal(this.spinData?.winDescription!, "$", this.spinData.gameBalance! / 100)
                const interval = setInterval(() => {
                    if (!this.game.assetsController?.winModal?.modalWrapper.visible) {
                        clearInterval(interval)
                        return resolve(true)
                    }
                }, 10)
            } else {
                return resolve(true)
            }

        })
    }


    CheckForWin = (event: SpinEvent, index: number) => {
        return new Promise(async (resolve) => {
            const {elements, win} = event
            let posX = 0
            let posY = 0
            let winResult = 0
            if (this.game.assetsController?.winLine) {
                this.game.assetsController.winLine.setWinLine(event.winLineIndex)
            }

            if (event.sumOfWild > 1) {
                winResult = (win / 100) / event.sumOfWild
            } else winResult = win / 100
            for (let j = 0; j < elements.length; j++) {
                const pos = elements[j]
                posX += pos[0]
                posY += pos[1]
                const symbol = this.reels[pos[0]].symbols[pos[1]]
                if (j == elements.length - 1) {
                    if (!this.reels[pos[0]].symbols[pos[1]].isDestroyed) {
                        symbol.win()

                        const mult = new BaseAmount(this.game, this.app)
                        mult.container.x = 233 * posX / elements.length
                        mult.container.y = 330 + 233 * posY / elements.length
                        mult.setValue('$', winResult, event.sumOfWild ? `${event.sumOfWild}` : "")
                        if (event.sumOfWild) {
                            mult.setMultiplier(event.sumOfWild)
                        }
                        this.rootContainer?.addChild(mult.container)
                        await mult.showValue()

                    }
                } else {
                    symbol.win()
                }

                setTimeout(() => {
                    return resolve(true)
                }, event.sumOfWild > 1 ? 2000 : 1200)
            }
        })
    }

    getCurrentSpeed = (isSkipAnim?: boolean, isFsActive: boolean = false) => {
        const isTurboMode = LocalStorage.getItem('isTurboEnabled')
        if (!isFsActive && isTurboMode) {
            this.currentSpeed = 0.5
        } else {
            this.currentSpeed = 1;
        }
        if (!isFsActive && isSkipAnim && this.isRunning) {
            this.currentSpeed = 0.5;
            this.game.assetsController?.playButton?.changeAplhaAndButtonMode(true);
        } else {
            this.game.assetsController?.playButton?.changeAplhaAndButtonMode(false);
        }
    }
    getBalance = (value: number) => {
        this.balance = value;
    }
}
