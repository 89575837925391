import * as PIXI from 'pixi.js';
import { Application } from 'pixi.js';
import { PixiGame } from '../../index';
import ConfirmBuyButton from '../BuyBonusButton/confirmBuyButton';
import CustomButton from '../CustomButton';

class BuyBonusModal {
	app: Application;
	game: PixiGame;
	modalWrapper: PIXI.Container;
	windowBackground: PIXI.Graphics;
	modal: PIXI.Container;
	background: PIXI.Graphics;
	title: PIXI.Text;
	maxCost: PIXI.Text;
	minCost: PIXI.Text;
	maxBonusAmount: PIXI.Text;
	minBonusAmount: PIXI.Text;
	maxBonusSprite: PIXI.Sprite;
	minBonusSprite: PIXI.Sprite;

	closeButton?: CustomButton;
	confirmButtonMax?: ConfirmBuyButton;
	confirmButtonMin?: ConfirmBuyButton;
	isModalOpen: boolean;

	constructor(game: PixiGame, app: Application) {
		this.game = game;
		const betValue = Math.trunc((this.game.initialData?.getBetValue() || 1))
		this.app = app;
		this.isModalOpen = false;
		this.modal = new PIXI.Container();
		this.modalWrapper = new PIXI.Container();
		this.background = new PIXI.Graphics();
		this.windowBackground = new PIXI.Graphics();
		this.title = new PIXI.Text('BUY BONUS', {
			fontFamily: 'Rubik',
			fontSize: 44,
			fontWeight: '700',
			lineHeight: 52.8,
			fill: '#E2F4F2',
		});
		this.maxCost = new PIXI.Text(`€ ${Math.trunc((400 * Number(betValue)))}`, {
			fontFamily: 'Rubik',
			fontSize: 44,
			fontWeight: '700',
			lineHeight: 52.8,
			fill: '#F9AD29',
		});

		this.minCost = new PIXI.Text(`€ ${Math.trunc((100 * Number(betValue)))}`, {
			fontFamily: 'Rubik',
			fontSize: 44,
			fontWeight: '700',
			lineHeight: 52.8,
			fill: '#F9AD29',
		});

		this.maxBonusAmount = new PIXI.Text('10 FREE SPINS', {
			fontFamily: 'Rubik',
			fontSize: 44,
			fontWeight: '700',
			lineHeight: 52.8,
			fill: '#F9AD29',
		});

		this.minBonusAmount = new PIXI.Text('6-10 FREE SPINS', {
			fontFamily: 'Rubik',
			fontSize: 44,
			fontWeight: '700',
			lineHeight: 52.8,
			fill: '#F9AD29',
		});

		this.maxBonusSprite = new PIXI.Sprite(
			this.app.loader.resources['rightBonus'].texture!
		);

		this.minBonusSprite = new PIXI.Sprite(
			this.app.loader.resources['leftBonus'].texture!
		);
	}

	initialize = async () => {
		await this.setupButtons();
		this.modalWrapper.x = 0;
		this.modalWrapper.y = 0;
		this.modalWrapper.width = this.app.screen.width;
		this.modalWrapper.height = this.app.screen.height + 32;
		this.modalWrapper.visible = false;
		this.modalWrapper.zIndex = 10000;

		this.modal.width = 1618;
		this.modal.height = 879;

		this.windowBackground.beginFill(0x151515);
		this.windowBackground.drawRect(
			0,
			0,
			this.app.screen.width,
			this.app.screen.height + 32
		);
		this.windowBackground.endFill();
		this.windowBackground.alpha = 0.8;

		this.background.beginFill(0x151515);
		this.background.drawRoundedRect(0, 0, 1618, 879, 10);
		this.background.endFill();

		this.windowBackground.zIndex = 1;
		this.modal.zIndex = 15;

		this.title.x = 681;
		this.title.y = 47;

		this.maxCost.x = this.confirmButtonMax!.button.x + this.confirmButtonMax!.button.width/2 - this.maxCost.width/2;
		this.maxCost.y = 605;

		this.minCost.x = this.confirmButtonMin!.button.x + this.confirmButtonMin!.button.width/2 - this.minCost.width/1.9;
		this.minCost.y = 605;

		this.minBonusAmount.x = this.confirmButtonMin!.button.x + this.confirmButtonMin!.button.width/2 - this.minBonusAmount.width/2;
		this.maxCost.y = 605;
		this.minBonusAmount.y = 171;

		this.maxBonusAmount.x = this.confirmButtonMax!.button.x + this.confirmButtonMax!.button.width/2 - this.maxBonusAmount.width/2;
		this.maxCost.y = 605;
		this.maxBonusAmount.y = 171;

		this.maxBonusSprite.width = 533;
		this.maxBonusSprite.height = 321;
		this.maxBonusSprite.x = this.confirmButtonMax!.button.x + this.confirmButtonMax!.button.width/2 - this.maxBonusSprite.width/2;
		this.maxBonusSprite.y = 254;

		this.minBonusSprite.width = 533;
		this.minBonusSprite.height = 321;
		this.minBonusSprite.x = this.confirmButtonMin!.button.x + this.confirmButtonMin!.button.width/2 - this.minBonusSprite.width/2;
		this.minBonusSprite.y = 254;

		this.app.stage.addChild(this.modalWrapper);
		this.modalWrapper.addChild(this.windowBackground!);
		this.modalWrapper.addChild(this.modal!);
		this.modal.addChild(this.background!);
		this.background.addChild(this.title!);
		this.background.addChild(this.maxCost!);
		this.background.addChild(this.minCost!);
		this.background.addChild(this.minBonusAmount!);
		this.background.addChild(this.maxBonusAmount!);
		this.background.addChild(this.maxBonusSprite);
		this.background.addChild(this.minBonusSprite);
	};

	showModal = (isActive: boolean) => {
		if (isActive) {
			this.modalWrapper.visible = true;
			this.game.gameContainer.interactiveChildren = false;
			this.isModalOpen = true;
		} else {
			this.modalWrapper.visible = false;
			this.game.gameContainer.interactiveChildren = true;
			this.isModalOpen = false;
		}
	};

	showMaxModal = async (isActive: boolean) => {
		await this.showModal(false);
		this.game.gameContainer.interactiveChildren = false;
		this.game.assetsController?.buyMaxBonusModal?.showModal(true);
	};

	showMinModal = async (isActive: boolean) => {
		await this.showModal(false)
		this.game.gameContainer.interactiveChildren = false;
		this.game.assetsController?.buyMinBonusModal?.showModal(true);
	};

	onClickAndTouch = (element: any, func: Function, state: boolean) => {
		element.button.on('touchstart', () => func(state));
		element.button.on('click', (e:any) => this.leftClickAutoPlayButton(e, func, state));
	};

	leftClickAutoPlayButton = (e:any, func: Function, state: boolean) => {
		if(e.data.button === 0){
			func(state);
		};
	}

	changeMinCost = (betValue:number) => {
		this.minCost.text = `€ ${Math.trunc((100 * betValue))}`
	}
	changeMaxCost = (betValue:number) => {
		this.maxCost.text = `€ ${Math.trunc((400 * betValue))}`
	}

	setupButtons = async () => {
		this.closeButton = new CustomButton(
			this.game,
			this.app,
			'closeButtonDefault',
			'closeButtonHover',
			'closeButtonDisabled',
			52,
			52
		);
		this.confirmButtonMax = new ConfirmBuyButton(
			this.game,
			this.app,
			'confirmButtonDefault',
			'confirmButtonHover',
			'confirmButtonDisabled',
			256,
			90,
			'BUY'
		);

		this.confirmButtonMin = new ConfirmBuyButton(
			this.game,
			this.app,
			'confirmButtonDefault',
			'confirmButtonHover',
			'confirmButtonDisabled',
			256,
			90,
			'BUY'
		);

		this.onClickAndTouch(this.closeButton, this.showModal, false);
		this.onClickAndTouch(this.confirmButtonMax, this.showMaxModal, false);
		this.onClickAndTouch(this.confirmButtonMin, this.showMinModal, false);

		this.onClickAndTouch(
			this.closeButton,
			this.game.assetsController?.buyBonusModalPortrait!.showModal!,
			false
		);
		this.onClickAndTouch(
			this.confirmButtonMax,
			this.game.assetsController?.buyBonusModalPortrait!.showMaxModal!,
			false
		);
		this.onClickAndTouch(
			this.confirmButtonMin,
			this.game.assetsController?.buyBonusModalPortrait!.showMinModal!,
			false
		);

		await this.closeButton.initialize();
		await this.confirmButtonMax.initialize();
		await this.confirmButtonMin.initialize();

		this.closeButton.button.x = 1587;
		this.closeButton.button.y = -12;

		this.confirmButtonMax.button.x = 999;
		this.confirmButtonMax.button.y = 688;

		this.confirmButtonMin.button.x = 372;
		this.confirmButtonMin.button.y = 688;

		this.background.addChild(this.closeButton.button!);
		this.background.addChild(this.confirmButtonMax.button!);
		this.background.addChild(this.confirmButtonMin.button!);
	};

	changeBuyBonusModalScale(scalingX: number, scalingY: number) {
		if (!this.modal) return;
		this.modal.scale.x = scalingX;
		this.modal.scale.y = scalingY;
	}

	changeBuyBonusModalPosition(x: number, y: number) {
		if (!this.modal) return;
		this.modal.x = x;
		this.modal.y = y;
	}
}

export default BuyBonusModal;
